import React from "react";
import { Container, Row, Col, Navbar } from "react-bootstrap";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelope, faPhone } from "@fortawesome/free-solid-svg-icons";

import SocialMediaIcons from "../SocialMediaIcons";
import "./Footer.scss";

const Footer = () => {
  return (
    <footer className='footer bg-light'>
      <Container>
        <Row className='align-items-center'>
          <Col className='text-center text-lg-start'>
            <a href='mailto:office@sportmakers.ro' className='footer-link'>
              <FontAwesomeIcon icon={faEnvelope} className='footer-icon' />{" "}
              office@sportmakers.ro
            </a>
          </Col>
          <Col className='text-center'>
            <Navbar.Brand as={Link} to='/'>
              <img
                src='/logo192.png'
                alt='Sportmakers logo'
                className='footer-logo'
              />
            </Navbar.Brand>
          </Col>
          <Col className='text-center text-lg-end'>
            <a href='tel:+40748814772' className='footer-link'>
              <FontAwesomeIcon icon={faPhone} className='footer-icon' /> +40 748
              814 772
            </a>
          </Col>
        </Row>
        <SocialMediaIcons />
      </Container>
    </footer>
  );
};

export default Footer;
