import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFacebook,
  faYoutube,
  faInstagram,
  faLinkedin,
  faTwitter,
} from "@fortawesome/free-brands-svg-icons";

import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

import "./SocialMediaIcons.scss";

const SocialMediaIcons = () => (
  <Row className='mt-3'>
    <Col className='text-center'>
      <div className='social-icons-with-lines'>
        <hr className='line' />
        <div className='social-icons'>
          <a
            href='https://www.facebook.com'
            className='social-icon'
            target='_blank'
            rel='noopener noreferrer'
          >
            <FontAwesomeIcon icon={faFacebook} size='2x' />
          </a>
          <a
            href='https://www.youtube.com'
            className='social-icon'
            target='_blank'
            rel='noopener noreferrer'
          >
            <FontAwesomeIcon icon={faYoutube} size='2x' />
          </a>
          <a
            href='https://www.instagram.com'
            className='social-icon'
            target='_blank'
            rel='noopener noreferrer'
          >
            <FontAwesomeIcon icon={faInstagram} size='2x' />
          </a>
          <a
            href='https://www.linkedin.com'
            className='social-icon'
            target='_blank'
            rel='noopener noreferrer'
          >
            <FontAwesomeIcon icon={faLinkedin} size='2x' />
          </a>
          <a
            href='https://www.twitter.com'
            className='social-icon'
            target='_blank'
            rel='noopener noreferrer'
          >
            <FontAwesomeIcon icon={faTwitter} size='2x' />
          </a>
        </div>
        <hr className='line' />
      </div>
    </Col>
  </Row>
);

export default SocialMediaIcons;
