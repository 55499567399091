import React, { useState } from "react";
import { Button } from "react-bootstrap";
import { ImageSlider, AboutSection } from "../../components";
import "./Home.scss";

const Home = () => {
  const [selectedCard, setSelectedCard] = useState(null);

  const cards = [
    {
      title: "1. Expertiză tehnică",
      description: `Compania noastră gestionează toate etapele, de la concept și proiectare până la execuția
                    finală. Astfel, economisiți timp, energie și bani, beneficiind de pachete de servicii complete la
                    prețuri mai avantajoase.`,
    },
    {
      title: "2. Grafic clar de execuție",
      description: `Primiți încă de la început un plan detaliat al proiectului, cu termene de execuție clare, astfel
                    încât să aveți mereu controlul asupra procesului.`,
    },
    {
      title: "3. Materiale de înaltă calitate",
      description: `Utilizăm doar cele mai bune materiale și tehnici de construcție pentru a ne asigura că
facilitățile sportive pe care le construim sunt durabile și sigure. Aceasta înseamnă terenuri și
suprafețe care rezistă în timp și oferă performanțe excelente.`,
    },
    {
      title: "4. Personalizare și flexibilitate",
      description: `Fiecare proiect este unic, iar noi lucrăm îndeaproape cu tine pentru a proiecta și construi
facilități care să răspundă nevoilor tale specifice. Oferim soluții personalizate, adaptate
cerințelor tale individuale, asigurându-ne că fiecare detaliu este perfect ajustat la viziunea ta.`,
    },
    {
      title: "5. Management complet al proiectului",
      description: `Cu noi, beneficiezi de servicii integrate pentru tot procesul. Nu va trebui să te ocupi de
interacțiuni cu multiple părți sau să coordonezi diferiți furnizori, nici să gestionezi aspecte
logistice complexe. Totul este gestionat de echipa noastră, astfel încât tu să te poți concentra
pe ceea ce este cu adevărat important.`,
    },
    {
      title: "6. Suport continuu",
      description: `Ne angajăm să fim partenerul tău de încredere, oferindu-ți suport continuu și asistență chiar și
după finalizarea proiectului.`,
    },
  ];

  return (
    <div className='home-container'>
      <div className='main-content'>
        <h1>Mișcare pentru generații</h1>
        <Button className='btn-oferta'>Ofertă primării</Button>
        <Button
          className='btn-ask'
          onClick={() =>
            (window.location.href = "mailto:office@sportmakers.ro")
          }
        >
          Cere ofertă
        </Button>
      </div>

      <div className='info-section'>
        <div className='header-with-lines'>
          <hr className='line' />
          <h2>De ce să alegi Sport Makers?</h2>
          <hr className='line' />
        </div>

        <div className='cards-container'>
          {cards.map((card, index) => {
            const isSelected = selectedCard === index;
            return (
              <div
                className={`card ${isSelected ? "selected" : ""}`}
                key={`${card.title}-${index}`}
                onClick={() => setSelectedCard(index)}
              >
                <h3>{card.title}</h3>
                <p>{card.description}</p>
              </div>
            );
          })}
        </div>
      </div>

      <AboutSection />

      <ImageSlider />
    </div>
  );
};

export default Home;
