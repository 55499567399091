import React, { useState } from "react";
import { Col, Row } from "react-bootstrap";

import "./AboutUs.scss";

const AboutUs = () => {
  const [selectedCard, setSelectedCard] = useState(null);

  const cards = [
    {
      title: "1. Expertiză tehnică",
      description: `Compania noastră gestionează toate etapele, de la concept și proiectare până la execuția
                    finală. Astfel, economisiți timp, energie și bani, beneficiind de pachete de servicii complete la
                    prețuri mai avantajoase.`,
    },
    {
      title: "2. Grafic clar de execuție",
      description: `Primiți încă de la început un plan detaliat al proiectului, cu termene de execuție clare, astfel
                    încât să aveți mereu controlul asupra procesului.`,
    },
    {
      title: "3. Materiale de înaltă calitate",
      description: `Utilizăm doar cele mai bune materiale și tehnici de construcție pentru a ne asigura că
facilitățile sportive pe care le construim sunt durabile și sigure. Aceasta înseamnă terenuri și
suprafețe care rezistă în timp și oferă performanțe excelente.`,
    },
    {
      title: "4. Personalizare și flexibilitate",
      description: `Fiecare proiect este unic, iar noi lucrăm îndeaproape cu tine pentru a proiecta și construi
facilități care să răspundă nevoilor tale specifice. Oferim soluții personalizate, adaptate
cerințelor tale individuale, asigurându-ne că fiecare detaliu este perfect ajustat la viziunea ta.`,
    },
    {
      title: "5. Management complet al proiectului",
      description: `Cu noi, beneficiezi de servicii integrate pentru tot procesul. Nu va trebui să te ocupi de
interacțiuni cu multiple părți sau să coordonezi diferiți furnizori, nici să gestionezi aspecte
logistice complexe. Totul este gestionat de echipa noastră, astfel încât tu să te poți concentra
pe ceea ce este cu adevărat important.`,
    },
    {
      title: "6. Suport continuu",
      description: `Ne angajăm să fim partenerul tău de încredere, oferindu-ți suport continuu și asistență chiar și
după finalizarea proiectului.`,
    },
  ];

  const valors = [
    {
      title: "Calitate",
      description:
        "Ne angajăm să oferim servicii și produse de cea mai înaltă calitate.",
    },
    {
      title: "Excelență",
      description: "Ne străduim să atingem perfecțiunea în tot ceea ce facem.",
    },
    {
      title: "Sustenabilitate",
      description: "Ne concentrăm pe soluții ecologice și sustenabile.",
    },
    {
      title: "Fiabilitate",
      description: "Oferim soluții de încredere și constante în timp.",
    },
    {
      title: "Comunitate",
      description: "Susținem și îmbunătățim comunitățile în care activăm.",
    },
    {
      title: "Integritate",
      description:
        "Ne comportăm cu onestitate și transparență în toate relațiile noastre.",
    },
    {
      title: "Creativitate",
      description:
        "Aplicăm idei inovatoare pentru a depăși așteptările clienților noștri.",
    },
  ];

  return (
    <div className='aboutus-container'>
      <div className='background-img'></div>

      <div className='valors-wrapper'>
        <div className='header-with-lines'>
          <hr className='line' />
          <h2>Valori</h2>
          <hr className='line' />
        </div>
        <Row className='aboutus-row'>
          <Col className='aboutus-column col-md-6 col-12'>
            <div className='column-wrapper'>
              <h4>{valors[0].title}</h4>
              <div>{valors[0].description}</div>
            </div>
          </Col>
          <Col className='aboutus-column'>
            <div className='column-wrapper'>
              <h4>{valors[1].title}</h4>
              <div>{valors[1].description}</div>
            </div>
          </Col>
        </Row>
        <Row className='aboutus-row'>
          <Col className='aboutus-column'>
            <div className='column-wrapper'>
              <h4>{valors[2].title}</h4>
              <div>{valors[2].description}</div>
            </div>
          </Col>
          <Col className='aboutus-column'>
            <div className='column-wrapper'>
              <h4>{valors[3].title}</h4>
              <div>{valors[3].description}</div>
            </div>
          </Col>
          <Col className='aboutus-column'>
            <div className='column-wrapper'>
              <h4>{valors[4].title}</h4>
              <div>{valors[4].description}</div>
            </div>{" "}
          </Col>
        </Row>
        <Row className='aboutus-row'>
          <Col className='aboutus-column'>
            <div className='column-wrapper'>
              <h4>{valors[5].title}</h4>
              <div>{valors[5].description}</div>
            </div>{" "}
          </Col>
          <Col className='aboutus-column'>
            <div className='column-wrapper'>
              <h4>{valors[6].title}</h4>
              <div>{valors[6].description}</div>
            </div>
          </Col>
        </Row>
      </div>
      <div>
        <div className='header-with-lines'>
          <hr className='line' />
          <h2>Misiune</h2>
          <hr className='line' />
        </div>
        <div className='power-points'>
          Să sprijinim sportivii și copiii din România prin crearea de terenuri
          de sport și locuri de joacă de înaltă calitate, asigurând un mediu
          sigur și plăcut pentru dezvoltarea lor fizică și emoțională
        </div>
      </div>
      <div>
        <div className='header-with-lines'>
          <hr className='line' />
          <h2>Viziune</h2>
          <hr className='line' />
        </div>
        <div className='power-points'>
          Să ajutăm oamenii să facă sport în condiții de top, performând pe
          orice suprafață.
        </div>
      </div>
      <div className='header-with-lines'>
        <hr className='line' />
        <h2>Ce îți oferim?</h2>
        <hr className='line' />
      </div>
      <div className='cards-container'>
        {cards.map((card, index) => {
          const isSelected = selectedCard === index;
          return (
            <div
              className={`card ${isSelected ? "selected" : ""}`}
              key={`${card.title}-${index}`}
              onClick={() => setSelectedCard(index)}
            >
              <h3>{card.title}</h3>
              <p>{card.description}</p>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default AboutUs;
