import React, { useEffect, useState } from "react";
import { Navbar, Nav, Container } from "react-bootstrap";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelope, faPhone, faFile } from "@fortawesome/free-solid-svg-icons";
import { faWhatsapp } from "@fortawesome/free-brands-svg-icons";

import "./Header.scss";

const Header = () => {
  const [isScrolled, setIsScrolled] = useState(false);
  const [isCollapsed, setIsCollapsed] = useState(false);

  const handleToggle = () => {
    setIsCollapsed(!isCollapsed);
  };

  const closeNavbar = () => {
    setIsCollapsed(false);
  };

  useEffect(() => {
    const handleScroll = () => {
      setIsScrolled(window.scrollY > 50);
    };

    window.addEventListener("scroll", handleScroll);

    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  return (
    <header className={isScrolled ? "scrolled" : ""}>
      <div className='small-header'>
        <a href='/path/to/your-file.pdf' className='header-link' download>
          <FontAwesomeIcon icon={faFile} className='header-icon' /> Ofertă
          primării
        </a>
        <a href='tel:+40748814772' className='header-link'>
          <FontAwesomeIcon icon={faPhone} className='header-icon' /> Sună-ne
        </a>
        <a href='mailto:office@sportmakers.ro' className='header-link'>
          <FontAwesomeIcon icon={faEnvelope} className='header-icon' />{" "}
          office@sportmakers.ro
        </a>
        <a
          href='https://wa.me/40748814772'
          className='header-link'
          target='_blank'
          rel='noopener noreferrer'
        >
          <FontAwesomeIcon icon={faWhatsapp} className='header-icon' /> Scrie-ne
        </a>
      </div>
      <Navbar expand='lg' className='navbar-custom'>
        <div className='mobile-logo'>
          <Navbar.Brand as={Link} to='/' onClick={closeNavbar}>
            <img src='/logo192.png' alt='Logo' className='mobile-log-img' />
          </Navbar.Brand>
        </div>
        {/* <Container className='d-flex align-items-center'>
          <Navbar.Toggle aria-controls='basic-navbar-nav' />
          <Navbar.Collapse id='basic-navbar-nav'>
            <Nav className='d-flex w-100 align-items-center'>
              <Nav.Item>
                <Nav.Link
                  as={Link}
                  to='/proiecte'
                  className={isScrolled ? "nav-link-small" : ""}
                >
                  Proiecte
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link
                  as={Link}
                  to='/produse'
                  className={isScrolled ? "nav-link-small" : ""}
                >
                  Produse
                </Nav.Link>
              </Nav.Item>
              <div className='navbar-brand-wrapper'>
                <Navbar.Brand as={Link} to='/'>
                  <img
                    src='/logo192.png'
                    alt='Logo'
                    className={`header-logo ${isScrolled ? "logo-small" : ""}`}
                  />
                </Navbar.Brand>
              </div>
              <Nav.Item>
                <Nav.Link
                  as={Link}
                  to='/despre-noi'
                  className={isScrolled ? "nav-link-small" : ""}
                >
                  Despre noi
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link
                  as={Link}
                  to='/contact'
                  className={isScrolled ? "nav-link-small" : ""}
                >
                  Contact
                </Nav.Link>
              </Nav.Item>
            </Nav>
          </Navbar.Collapse>
        </Container> */}
        <Container className='d-flex align-items-center'>
          <Navbar.Toggle
            aria-controls='basic-navbar-nav'
            onClick={handleToggle}
            aria-expanded={isCollapsed}
          />
          <Navbar.Collapse in={isCollapsed} id='basic-navbar-nav'>
            <Nav className='d-flex w-100 align-items-center'>
              <Nav.Item>
                <Nav.Link
                  as={Link}
                  to='/proiecte'
                  className={isScrolled ? "nav-link-small" : ""}
                  onClick={closeNavbar}
                >
                  Proiecte
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link
                  as={Link}
                  to='/produse'
                  className={isScrolled ? "nav-link-small" : ""}
                  onClick={closeNavbar}
                >
                  Produse
                </Nav.Link>
              </Nav.Item>
              <div className='navbar-brand-wrapper'>
                <Navbar.Brand as={Link} to='/'>
                  <img
                    src='/logo192.png'
                    alt='Logo'
                    className={`header-logo ${isScrolled ? "logo-small" : ""}`}
                  />
                </Navbar.Brand>
              </div>
              <Nav.Item>
                <Nav.Link
                  as={Link}
                  to='/despre-noi'
                  className={isScrolled ? "nav-link-small" : ""}
                  onClick={closeNavbar}
                >
                  Despre noi
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link
                  as={Link}
                  to='/contact'
                  className={isScrolled ? "nav-link-small" : ""}
                  onClick={closeNavbar}
                >
                  Contact
                </Nav.Link>
              </Nav.Item>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </header>
  );
};

export default Header;
