import React from "react";

import "./Projects.scss";

const Projects = () => {
  return (
    <div className='main-content'>
      <h1>Mișcare pentru generații</h1>
      {/* <p>This is the main content area.</p> */}
    </div>
  );
};

export default Projects;
