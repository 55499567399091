import React from "react";

import "./Products.scss";

const Products = () => {
  return (
    <div className='main-content'>
      <h1>Mișcare pentru generații</h1>
      {/* <p>This is the main content area.</p> */}
    </div>
  );
};

export default Products;
