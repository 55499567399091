import React, { useEffect, useRef, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBuilding,
  faTachometerAlt,
  faUsers,
} from "@fortawesome/free-solid-svg-icons";
import "./AboutSection.scss";

const AboutSection = () => {
  const [isVisible, setIsVisible] = useState(false);
  const sectionRef = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        setIsVisible(entry.isIntersecting);
      },
      {
        rootMargin: "0px",
        threshold: 0.1, // Trigger when 10% of the element is in view
      }
    );

    if (sectionRef.current) {
      observer.observe(sectionRef.current);
    }

    return () => {
      if (sectionRef.current) {
        observer.unobserve(sectionRef.current);
      }
    };
  }, []);

  return (
    <div className='about-us' ref={sectionRef}>
      <div class='header-with-lines'>
        <hr class='line' />
        <h2>Cine suntem</h2>
        <hr class='line' />
      </div>
      <div className={`right-column ${isVisible ? "visible" : ""}`}>
        <div className='content-container'>
          <div className='content-item'>
            <FontAwesomeIcon icon={faBuilding} className='icon' />
            <div className='text'>
              <p>
                Noi suntem Sport Makers, o companie dinamică și inovatoare în
                domeniul construcțiilor sportive din România. Echipa noastră,
                formată din tineri pragmatici și dedicați, este unită de valori
                precum profesionalismul, munca asiduă și empatia față de fiecare
                client.
              </p>
            </div>
          </div>
          <div className='content-item'>
            <FontAwesomeIcon icon={faTachometerAlt} className='icon' />
            <div className='text'>
              <p>
                Avem o vastă experiență în crearea și amenajarea de facilități
                sportive și locuri de joacă la cheie. Suntem aici pentru a te
                ajuta să transformi visul tău în realitate, oferind soluții
                personalizate care răspund perfect nevoilor tale.
              </p>
            </div>
          </div>
          <div className='content-item'>
            <FontAwesomeIcon icon={faUsers} className='icon' />
            <div className='text'>
              <p>
                La Sport Makers, ne mândrim că lucrăm îndeaproape cu fiecare
                client pentru a asigura cea mai bună calitate și durabilitate în
                fiecare proiect. Suntem pasionați de ceea ce facem și ne dorim
                să aducem un impact pozitiv în comunitățile din România prin
                facilități sportive de top.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutSection;
