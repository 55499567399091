import React from "react";
import Slider from "react-slick";

import "./ImageSlider.scss";

const ImageSlider = () => {
  const settings = {
    dots: true,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
  };

  return (
    <div className='slider-container'>
      <Slider {...settings}>
        <div>
          <img src={require("../../img/imageslider/field.jpg")} alt='Slide 1' />
        </div>
        <div>
          <img src={require("../../img/imageslider/grass.jpg")} alt='Slide 1' />
        </div>
        <div>
          <img
            src={require("../../img/imageslider/grassfield.jpg")}
            alt='Slide 1'
          />
        </div>
        <div>
          <img
            src={require("../../img/imageslider/artificialgrass.jpg")}
            alt='Slide 1'
          />
        </div>
        <div>
          <img
            src={require("../../img/imageslider/grassfootball.jpeg")}
            alt='Slide 1'
          />
        </div>
        <div>
          <img
            src={require("../../img/imageslider/kidsplayground.jpg")}
            alt='Slide 1'
          />
        </div>
        <div>
          <img
            src={require("../../img/imageslider/kidsplayground2.jpg")}
            alt='Slide 1'
          />
        </div>
        <div>
          <img
            src={require("../../img/imageslider/kidsplayground3.jpg")}
            alt='Slide 1'
          />
        </div>
        <div>
          <img
            src={require("../../img/imageslider/stadium.jpg")}
            alt='Slide 1'
          />
        </div>
        <div>
          <img
            src={require("../../img/imageslider/stadium2.jpg")}
            alt='Slide 1'
          />
        </div>
        <div>
          <img
            src={require("../../img/imageslider/tennis.jpg")}
            alt='Slide 1'
          />
        </div>
        <div>
          <img
            src={require("../../img/imageslider/tennis2.jpg")}
            alt='Slide 1'
          />
        </div>
        <div>
          <img
            src={require("../../img/imageslider/syntheticgrass.jpg")}
            alt='Slide 1'
          />
        </div>
      </Slider>
    </div>
  );
};

export default ImageSlider;
